<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st5" d="M210.313,29.453C210.274,10.996,199.31,0.182,180.81,0.176c-37.21-0.012-74.421-0.012-111.631,0
                C50.716,0.182,39.667,11.13,39.663,29.453c-0.013,63.696-0.013,127.393,0,191.09c0.004,18.317,11.059,29.276,29.512,29.282
                c37.211,0.012,74.421,0.013,111.633,0c18.669-0.006,29.51-10.944,29.516-29.771C210.335,188.368,210.376,61.301,210.313,29.453z
                M124.877,20.858c20.036-0.078,36.62,16.429,36.669,36.5c0.05,20.198-16.22,36.523-36.441,36.567
                c-20.249,0.044-36.63-16.196-36.666-36.352C88.402,37.471,104.812,20.936,124.877,20.858z M125.056,229.143
                c-31.023,0.043-56.105-25.297-56.097-56.673c0.009-30.11,25.133-55.3,55.232-55.374c31.616-0.078,56.801,24.611,56.857,55.734
                C181.104,203.711,155.858,229.101,125.056,229.143z"/>
            <path class="st5" d="M124.909,131.671c-22.985,0.028-41.449,18.574-41.366,41.549c0.081,22.597,18.821,41.357,41.276,41.322
                c23.131-0.036,41.658-18.717,41.622-41.965C166.406,150.016,147.751,131.643,124.909,131.671z M124.86,199.876
                c-14.558-0.096-26.614-12.181-26.665-26.732c-0.052-14.687,11.766-26.675,26.426-26.808c14.985-0.135,27.103,11.712,27.164,26.557
                C151.845,187.626,139.526,199.972,124.86,199.876z"/>
            <path class="st5" d="M124.992,79.349c12.195-0.036,21.981-9.844,21.966-22.016c-0.016-11.946-10.256-22.039-22.216-21.894
                c-11.79,0.142-21.682,10.117-21.718,21.897C102.989,69.465,112.887,79.385,124.992,79.349z"/> 
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'iconSpeaker',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>